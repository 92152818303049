<template>
    <div d="carouselPropertyIndicators" class="carousel slide component" data-bs-ride="carousel">

        <div class="carousel-indicators">
            <div v-for="(imagePath, i) in imagePaths" :key="i">
                <button v-if="i == 0" type="button" data-bs-target="#carouselPropertyIndicators" :data-bs-slide-to="i" class="active" aria-current="true" aria-label="Slide i"></button>
                <button v-else type="button" data-bs-target="#carouselPropertyIndicators" :data-bs-slide-to="i" aria-label="Slide i"></button>
            </div>
        </div>

        <div class="carousel-inner" >
            <div v-for="(imagePath, i) in imagePaths" :key="i">
                <div v-if="i == 0" class="carousel-item active">
                    <img :src="imagePath" class="d-block w-100" alt="...">
                </div>
                <div v-else class="carousel-item">
                    <img :src="imagePath" class="d-block w-100" alt="...">
                </div>
            </div>
        </div>


        <button v-if="imageCount > 1" class="carousel-control-prev" type="button" data-bs-target="#carouselPropertyIndicators" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>

        <button v-if="imageCount > 1" class="carousel-control-next" type="button" data-bs-target="#carouselPropertyIndicators" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>

        <div class="carousel-caption d-none d-md-block">
            <h5 v-if="sellPrice">SOLD {{ currency }}{{ sellPrice.toLocaleString('en-US') }}</h5>
            <h5 v-else>SOLD</h5>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            baseImageName: '',
            imageCount: 0,
            fileExtension: '',
            imagePaths: [],
            sellPrice: 0,
            currency: '$',
        }
    },
    props: {
        image: {
            type: Object,
            required: true,
        },
    },
    created() {
        this.imageCount = this.image.count;
        this.baseImageName = this.image.name;
        this.fileExtension = this.image.extension;
        this.sellPrice = this.image.price;

        this.loadImagePaths();
    },
    methods: {
        loadImagePaths() {
            let assetsFolder = '/img/';
            for (let i = 0; i < this.imageCount; i++) {
                let imagePath = assetsFolder.concat(this.baseImageName, '_', i, this.fileExtension);
                console.log(imagePath);

                this.imagePaths.push(imagePath);
            }
        }
    }
}
</script>

<style scoped>
.component {
    box-shadow: 2px 2px 4px black;
    margin-top: 2em;
}
h5 {
    text-align: left;
    background-image: linear-gradient(to right, rgba(220, 40, 40, 1), rgba(220, 40, 40, 1), rgba(255, 0, 0, 0));
    padding: 8px;
}
img {
  height: 15em;
}
</style>
