<template>
    <div class="background-img">
        <div class="mask">
            <div class="container text-white d-flex align-items-center justify-content-center text-center h-100">
                <div class="row">
                    <div class="text">  
                        <div class="col-md-12">
                            <h1>Paul Atuf Realty</h1>
                        </div>
                        <div class="col-md-12">
                            <h2>Real Estate Brokerage based in California.</h2>
                        </div>
                        <div class="col-md-12">
                            <h2>(714) 452-4157</h2>
                        </div>
                        <div class="col-md-12">
                            <h2>DRE#01192587</h2>
                        </div>
                        <div class="col-md-12 mt-5">
                            <a href="#properties">Properties Sold</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container" id="properties">
        <div class="row">
            <div v-for="image in images" class="col-md-4" :key="image.id">
                <Location :image="image"></Location>
            </div>
        </div>
    </div>
    <footer class="bg-dark text-center text-lg-start">
      <!-- Copyright -->
      <div class="text-center text-white p-3 mt-5">
          <p class="mt-3">Copyright © {{ new Date().getFullYear() }} paulatufrealty</p>
      </div>
      <!-- Copyright -->
    </footer>
</template>

<script>
import Location from '../components/Location.vue'

export default {
    name: 'HomeView',
    components: {
        Location,
    },
    data() {
        return {
            images: [
                {
                    id: 0,
                    name: 'house1',
                    count: 6,
                    extension: '.jpg',
                    price: 1005000
                },
                {
                    id: 1,
                    name: 'house2',
                    count: 1,
                    extension: '.jpg',
                },
                {
                    id: 2,
                    name: 'house3',
                    count: 1,
                    extension: '.jpg',
                },
                {
                    id: 3,
                    name: 'house4',
                    count: 1,
                    extension: '.jpg',
                },
                {
                    id: 4,
                    name: 'house5',
                    count: 1,
                    extension: '.jpg',
                },
                {
                    id: 5,
                    name: 'house6',
                    count: 3,
                    extension: '.jpg',
                },
                {
                    id: 6,
                    name: 'house7',
                    count: 1,
                    extension: '.jpg',
                },
            ]
        }
    }
}

</script>

<style scoped>
.background-img {
    background-image: url('@/assets/banner.jpg');;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    height: 100vh;
}

a {
    text-decoration: none;
    background-color: #992222;
    padding: 1em;
    border-radius: 2px;
    color: white;
    box-shadow: 2px 2px 10px #000;
    font-weight: bold;
    font-size: 1em;
}

a:hover {
    background-color: #881111;
}

.mask {
    height: 100vh;
}

h1 {
    font-size: 2.5em;
}

h2 {
    font-size: 1.2em;
}

.text {
    text-shadow: 2px 2px 4px #111;
}

p {
    font-size: 1.2em;
}
</style>

