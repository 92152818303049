<template>
    <!--
    <NavBar></NavBar>
    -->
    <div class="container-fluid p-0">
        <router-view/>
    </div>
</template>

<script>
import NavBar from './components/NavBar.vue';

export default {
    name: 'App',
    components: {
        NavBar,
    }
}
</script>
<style>
body {
    background-color: #E3E3E3;
}

</style>
